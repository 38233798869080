
import { defineComponent } from "vue";
import PhoneCard from "./PhoneCard/index.vue";
export default defineComponent({
  components: { PhoneCard },
  setup() {
    return {
      appName: "开心虎用户端App",
      msglist: [
        "1、智能管理：大数据、云计算的开发应用，实现从下单到采购到生产到配送全流程智慧化的统计分析，让中央厨房的管理运营减负增效！",
        "2、食品安全：响应国家互联网+明厨亮灶政策，利用低码流压缩技术、推流技术、云转发技术、高并发技术，实现家长实时在APP上查看食品生产及孩子就餐情况，让食品安全看得见！",
      ],
      moduleList: [
        {
          imgurl: require("../../assets/prod-kxh/phon1-index.png"),
          title: "首页",
          msg: "首页包括banner运营位置，餐费缴纳、看我、菜谱、食材溯源等功能。",
          order: true,
        },
        {
          imgurl: require("../../assets/prod-kxh/phon2-jn.png"),
          title: "餐费缴纳",
          msg: "为孩子选择套餐，进行缴费，便捷支付。",
          order: false,
        },
        {
          imgurl: require("../../assets/prod-kxh/phon3-lookme.png"),
          title: "看我",
          msg: "随时随地观看孩子在校用餐情况，了解孩子饮食健康。",
          order: true,
        },
        {
          imgurl: require("../../assets/prod-kxh/phon4-caipu.png"),
          title: "菜谱",
          msg: "提前了解孩子在校一月用餐菜谱，关注孩子营养数据。",
          order: false,
        },
        {
          imgurl: require("../../assets/prod-kxh/phon5-scroue.png"),
          title: "食材溯源",
          msg: "了解每一种食材的来源，让家长放心，孩子吃的安心。",
          order: true,
        },
      ],
    };
  },
});
